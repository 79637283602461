import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { getResolvedImage } from '@/core/sanityAPI/types';
import styles from './blog-post-preview.module.scss';
import ArrowRightIcon from '../../assets/img/arrow-right.inline.svg';
import { LayoutContainer } from '../../components/layout';
import { Block } from '@/blocks/base';

const BlogPostPreview = Block(
  'blogPostPreview',
  ({ title, description, author, tag, slug }) => {
    if (!author) {
      return null;
    }

    const resolvedImage = getResolvedImage(author.avatar);

    return (
      <div className={styles.blogPreviewsContainer}>
        <div className={styles.content}>
          <LayoutContainer>
            <h3 className={styles.heading}>Get to know us</h3>
            <Link href={`/blog/${slug}`} className={styles.blogPostPreviewLink}>
              <div className={styles.blogPostPreviewContainer}>
                <div className={styles.blogPostPreviewImage}>
                  <div className={styles.blogPostPreviewTopic}>
                    {tag?.name || ''}
                  </div>
                  {resolvedImage && (
                    <Image
                      src={resolvedImage.src}
                      alt={author?.name || ''}
                      className={styles.image}
                      width={resolvedImage.width}
                      height={resolvedImage.height}
                    />
                  )}
                  <div className={styles.fullNameAndPos}>
                    <div className={styles.name}>{author.name}</div>
                  </div>
                </div>
                <div className={styles.blogPostPreviewContent}>
                  <div className={styles.topPart}>
                    <div className={styles.previewHeading}>{title}</div>
                    <p className={styles.previewDescription}>{description}</p>
                  </div>
                  <span className={styles.actionBtn}>
                    Go to publication
                    <ArrowRightIcon className={styles.arrowRight} />
                  </span>
                </div>
              </div>
            </Link>
          </LayoutContainer>
        </div>
      </div>
    );
  },
);

export { BlogPostPreview };
