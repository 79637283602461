import { PageSchema } from '@/schema';
import React from 'react';

type ElementType<T extends unknown[] | undefined> = T extends (infer U)[]
  ? U
  : never;
type PageBlocks = PageSchema['blocks'];
type PageBlock = ElementType<PageBlocks>;

// type PageBlock = PageBlocks[0];

export type BlockTypes = PageBlock['_type'];

export type BlockType<T extends BlockTypes> = Extract<PageBlock, { _type: T }>;

interface BasicBlockProps {
  className?: string;
}

export function Block<T extends BlockTypes>(
  type: T,
  component: React.FC<BlockType<T> & BasicBlockProps>,
) {
  const blockComponent = React.memo(component);

  blockComponent.displayName = `Block:${type}`;

  return blockComponent;
}

interface BlockConfig<C extends BlockType<BlockTypes>> {
  component: React.ComponentType<BasicBlockProps & C>;
}

export type BlockMapping = {
  [key in BlockTypes]: BlockConfig<BlockType<key>> | undefined;
};
