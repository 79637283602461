import React, { useCallback, useContext, useRef } from 'react';
import { Block } from '@/blocks/base';
import styles from './architectural-review.section.module.scss';
import '../../components/blobCursor/blobCursor.module.scss';
import { LayoutContainer } from '../../components/layout';
import { RemoteImage } from '@/uikit/remote-image';
import { BlobCursor } from '../../components/blobCursor';
import { ModalContext } from '@/contexts/globalActions';

const ArchitecturalReviewSection = Block('architecturalReview', (props) => {
  const {
    heading,
    description,
    limitedSpot,
    claimBtnText,
    auditBtnText,
    authorImg,
    authorQuote,
    authorName,
  } = props;
  const openAuditTemplate = useCallback(() => {
    window.open(
      '/techery-audit-template(confidential).pdf',
      '_blank',
      'noopener,noreferrer',
    );
  }, []);
  const containerRef = useRef<HTMLDivElement>(null);
  const modalContext = useContext(ModalContext);

  return (
    <section className={styles.architecturalSectionContainer}>
      <LayoutContainer maxWidth>
        <h3 className={styles.heading}>{heading}</h3>
        <p className={styles.description}>{description}</p>
        <div className={styles.reviewRow}>
          <div className={styles.limitedSpot}>
            <p>{limitedSpot}</p>
            <div className={styles.claimSpotContainer} ref={containerRef}>
              <button
                onClick={modalContext?.setModalState}
                className={styles.button}
              >
                <span>{claimBtnText}</span>
              </button>
              <button
                className={styles.downloadAuditBtn}
                onClick={openAuditTemplate}
              >
                <span>{auditBtnText}</span>
              </button>
              <BlobCursor containerRef={containerRef} />
            </div>
          </div>
          <div className={styles.review}>
            <div className={styles.solImage}>
              <RemoteImage image={authorImg as any} alt="alt" />
            </div>
            <p>{authorQuote}</p>
            <span>{authorName}</span>
          </div>
        </div>
      </LayoutContainer>
    </section>
  );
});

export { ArchitecturalReviewSection };
