import { RemoteImage } from '@/uikit/remote-image';
import styles from './styles.module.scss';
import { PortableText } from '@portabletext/react';
import React from 'react';
import { SanityBlock } from 'sanity-codegen/types';
import dynamic from 'next/dynamic';
import { resolveRef } from '@/core/sanityAPI/client-resolvers';

const SyntaxHighlighter = dynamic(
  () => import('react-syntax-highlighter').then((m) => m.Prism),
  { ssr: true },
);

interface RichTextProps {
  body: SanityBlock[];
}

const components = {
  types: {
    image: (props: any) => {
      return <RemoteImage image={props.value} />;
    },
    code: (props: any) => {
      const codeProps = {
        key: props.value.key,
        language: props.value.language,
        showLineNumbers: true,
      };

      return (
        <>
          <i>{props.value.filename}</i>
          <SyntaxHighlighter {...codeProps}>
            {String(resolveRef(props.value.code)).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </>
      );
    },
  },
};

export const RichText = React.memo((props: RichTextProps) => {
  return (
    <div className={styles.portableTextStyle}>
      <PortableText value={props.body} components={components} />
    </div>
  );
});

RichText.displayName = 'RichText';
