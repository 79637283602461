import React from 'react';
import { sectionContent, sectionContainer } from './section.css';
export type SectionProps = {
  children: React.ReactNode;
  className?: string;
  contentStyle?: string;
};

const Section = ({
  children,
  className,
  contentStyle,
  ...otherProps
}: SectionProps) => (
  <section className={`${sectionContainer} ${className || ''}`} {...otherProps}>
    <div className={`${sectionContent} ${contentStyle || ''}`}>{children}</div>
  </section>
);

export default Section;
