import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PlaySvg from '/src/assets/img/play-icon.inline.svg';
import MutedIconSvg from '/src/assets/img/muted-icon.inline.svg';
import VolumeUpIconSvg from '/src/assets/img/volume-up-icon.inline.svg';
import styles from './hero-video.module.scss';
import YoutubeEmbededModal from '../../components/youtubeEmbededModal/youtubeEmbededModal';
import { clsx } from '@/uikit/utils';
import { Block } from '@/blocks/base';
import { resolveRef } from '@/core/sanityAPI/client-resolvers';
import { getResolvedImage } from '@/core/sanityAPI/types';

export interface VideoSrc {
  src: string;
  type: string;
}

type Caption = readonly [number, string];

export interface VideoConfig {
  preload: 'none' | 'metadata' | 'auto';
  videoSrc: VideoSrc[];
  fullVideoSrc: string;
  poster: string;
  captionsConf: readonly Caption[];
  heading: string;
  description: string;
  textPosition: 'left' | 'right';
  critical?: boolean;
}

interface VideoProgressProps {
  progress: number;
  heading: string;
  description: string;
  onChangeVideo: (videoIndex: number) => void;
  onHover: (videoIndex: number) => void;
  disabled: boolean;
  videoIndex: number;
}

const VideoProgress = (props: VideoProgressProps) => {
  const disabledClassName = props.disabled ? styles.disabled : '';

  const onHover = useCallback(() => {
    props.onHover(props.videoIndex);
  }, [props.onHover]);

  const onClick = useCallback(() => {
    props.onChangeVideo(props.videoIndex);
  }, [props.onHover]);

  return (
    <div
      className={clsx(styles.videoProgress, disabledClassName)}
      onClick={onClick}
      onMouseEnter={onHover}
      onTouchStart={onHover}
    >
      <progress
        max="100"
        value={props.progress || 0}
        className={styles.progressBar}
      />
      <h3 className={styles.heading}>{props.heading}</h3>
      <p className={styles.description}>{props.description}</p>
    </div>
  );
};

interface FullVideoBtnProps {
  onClick: () => void;
}

const FullVideoBtn = ({ onClick }: FullVideoBtnProps) => {
  return (
    <button onClick={onClick} className={styles.fullVideoBtn}>
      <PlaySvg />
      <span>Full Video</span>
    </button>
  );
};

const useVideoPlayer = (
  videoElements: React.RefObject<HTMLVideoElement[]>,
  videoConfigs: VideoConfig[],
  updateText: (value: string) => void,
) => {
  const [videoProgress, setVideoProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isFallbackUiShown, setIsFallbackUiShown] = useState(false);

  function currentVideoElement(): HTMLVideoElement | null {
    return (
      (videoElements.current && videoElements.current[currentVideoIndex]) ||
      null
    );
  }

  const changeVideo = useCallback(
    (index: number) => {
      const isCurrentVideo = currentVideoIndex === index;
      const videoElement = currentVideoElement();
      if (videoElement && !isCurrentVideo) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }

      if (!isCurrentVideo) {
        setIsLoading(true);
        setCurrentVideoIndex(index);
        updateText('');
      }
    },
    [videoConfigs, currentVideoIndex],
  );

  const handleVideoEnd = useCallback(
    (videoIndex: number) => {
      if (videoIndex !== currentVideoIndex) {
        return;
      }

      const index =
        currentVideoIndex === videoConfigs.length - 1
          ? 0
          : currentVideoIndex + 1;
      changeVideo(index);
    },
    [videoConfigs, currentVideoIndex],
  );

  const preloadVideo = useCallback(
    (index: number) => {
      if (index === currentVideoIndex) {
        return;
      }

      const videoElement =
        videoElements.current && videoElements.current[index];

      if (videoElement) {
        if (videoElement.readyState < videoElement.HAVE_CURRENT_DATA) {
          videoElement.preload = 'auto';
          videoElement.load();
        }
      }
    },
    [videoConfigs, currentVideoIndex],
  );

  const toggleVideoSound = useCallback(() => {
    setIsVideoMuted(!isVideoMuted);
  }, [isVideoMuted]);

  const pauseVideo = useCallback(() => {
    currentVideoElement()?.pause();
  }, [currentVideoIndex]);

  const playVideo = useCallback(() => {
    setIsFallbackUiShown(false);
    currentVideoElement()?.play();
  }, [currentVideoIndex]);

  const onSuspend = useCallback(() => {
    setIsFallbackUiShown(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      setIsLoading(true);
      currentVideoElement()?.play();
    } else {
      setIsVideoMuted(true);
    }
  }, [isVisible, currentVideoIndex]);

  const onScroll = () => {
    const videoElement = currentVideoElement();
    if (!videoElement) {
      setIsVisible(false);
      return;
    }

    if (videoElement.preload === 'none') {
      videoElement.preload = 'auto';
    }

    videoElements.current?.forEach((video) => {
      if (video.preload === 'none') {
        video.preload = 'metadata';
      }
    });

    const top = videoElement.getBoundingClientRect().top;
    const height = videoElement.offsetHeight;

    const isInVisibleArea = top + height >= 0 && height - top >= 0;

    if (!isVisible && isInVisibleArea) {
      videoElement.currentTime = 0;
    }

    setIsVisible(isInVisibleArea);
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  return {
    videoProgress,
    isLoading,
    setIsLoading,
    setVideoProgress,
    currentVideoIndex,
    setCurrentVideoIndex,
    handleVideoEnd,
    isVideoMuted,
    setIsVideoMuted,
    pauseVideo,
    playVideo,
    onSuspend,
    isVisible,
    toggleVideoSound,
    changeVideo,
    preloadVideo,
    isFallbackUiShown,
    setIsFallbackUiShown,
  };
};

interface VideoComponentProps {
  videoConfig: VideoConfig;
  index: number;
  handleVideoEnd: (videoIndex: number) => void;
  handleTimeUpdate: (videoIndex: number) => void;
  captureVideoRef: (videoIndex: number, ref: HTMLVideoElement) => void;
  onSuspend: () => void;
  muted: boolean;
  visible: boolean;
  loop: boolean;
  isLoading: boolean;
}

const VideoComponent = (props: VideoComponentProps) => {
  const onRef = useCallback(
    (el: HTMLVideoElement) => {
      props.captureVideoRef(props.index, el);
    },
    [props.captureVideoRef],
  );

  const onTimeUpdate = useCallback(() => {
    props.handleTimeUpdate(props.index);
  }, [props.handleTimeUpdate]);

  const onEnded = useCallback(() => {
    props.handleVideoEnd(props.index);
  }, [props.handleVideoEnd]);

  const videoStyle = useMemo(() => {
    return {
      display: props.visible ? 'block' : 'none',
    };
  }, [props.visible]);

  return (
    <>
      <video
        ref={onRef}
        style={videoStyle}
        preload={props.videoConfig.preload}
        onSuspend={props.onSuspend}
        onTimeUpdate={onTimeUpdate}
        onEnded={onEnded}
        playsInline
        muted={props.muted}
        loop={props.loop}
      >
        {props.videoConfig.videoSrc.map((src, index) => (
          <source key={index} src={src.src} type={src.type} />
        ))}
      </video>
    </>
  );
};

const resolveVideoUrls = (videos) => {
  return videos.map((video) => ({
    ...video,
    videoSrc: video.videoSrc.map((src) => ({
      src: resolveRef(src.src.asset).url,
      type: src.type,
    })),
    poster: getResolvedImage(video.poster.asset)?.src,
    captionsConf: video.captionsConf.map(
      ({ duration, text }) => [duration, text] as readonly [number, string],
    ),
  }));
};

const HeroVideo = Block('heroVideo', (props) => {
  const { darkBg, id } = props;
  const videos = resolveVideoUrls(props.videos);

  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const [text, updateText] = useState('');

  const {
    videoProgress,
    setVideoProgress,
    setIsLoading,
    setIsFallbackUiShown,
    isLoading,
    currentVideoIndex,
    handleVideoEnd,
    isVideoMuted,
    setIsVideoMuted,
    playVideo,
    onSuspend,
    isFallbackUiShown,
    pauseVideo,
    toggleVideoSound,
    changeVideo,
    preloadVideo,
  } = useVideoPlayer(videoRefs, videos, updateText);

  const captureVideoRef = useCallback(
    (index: number, ref: HTMLVideoElement) => {
      videoRefs.current[index] = ref;
    },
    [videoRefs],
  );

  const handleTimeUpdate = useCallback(
    (index: number) => {
      if (index !== currentVideoIndex) {
        return;
      }

      const videoElement = videoRefs.current[currentVideoIndex] || null;

      if (videoElement === null) {
        return;
      }

      const captionsConf: ReadonlyArray<[number, string]> =
        selectedVideo.captionsConf;
      if (captionsConf) {
        const captionIdx = captionsConf.findIndex(
          ([startTime, _]) => startTime >= videoElement.currentTime,
        );
        const desiredCaption = captionsConf[captionIdx - 1];
        if (desiredCaption) {
          updateText(desiredCaption[1]);
        } else {
          updateText('');
        }
      } else {
        updateText('');
      }

      setIsLoading(false);
      setIsFallbackUiShown(false);
      setVideoProgress(
        Math.ceil((videoElement.currentTime / videoElement.duration) * 100),
      );
    },
    [currentVideoIndex],
  );

  const [isModalShown, setIsModalShown] = useState(false);
  const showModal = useCallback(() => {
    setIsVideoMuted(true);
    setIsModalShown(true);
    pauseVideo();
  }, []);

  const closeModal = useCallback(() => {
    setIsModalShown(false);
    playVideo();
  }, []);

  const selectedVideo = videos[currentVideoIndex] || videos[0];

  return (
    <div id={id} className={styles.overlay}>
      {isFallbackUiShown && (
        <div className={styles.fallbackPlay}>
          <PlaySvg onClick={playVideo} />
        </div>
      )}
      <section className={clsx(styles.heroVideo, darkBg ? 'dark-bg' : '')}>
        {videos.map((_videoConfig, index) => {
          const isCurrentVideo = index === currentVideoIndex;
          const isMuted = !(isCurrentVideo && !isVideoMuted);
          return (
            <VideoComponent
              key={`${id}-${index}`}
              index={index}
              isLoading={isLoading}
              captureVideoRef={captureVideoRef}
              visible={isCurrentVideo}
              videoConfig={videos[index]}
              handleTimeUpdate={handleTimeUpdate}
              onSuspend={onSuspend}
              handleVideoEnd={handleVideoEnd}
              muted={isMuted}
              loop={videos.length === 1}
            />
          );
        })}

        <div className={styles.heroTextContainer}>
          <h1
            className={clsx(
              styles.heroText,
              styles[selectedVideo.textPosition],
            )}
          >
            {text && <span>{text}</span>}
          </h1>
        </div>
      </section>
      <div className={styles.heroTestimonials}>
        <div className={styles.heroTextMobileContainer}>
          <h1
            className={clsx(
              styles.heroText,
              styles[selectedVideo.textPosition],
            )}
          >
            {text && <span>{text}</span>}
          </h1>
        </div>
        <div className={styles.videoProgressContainer}>
          {videos.map(({ heading, description }, index) => {
            let progress = 0;
            const previousVideo = currentVideoIndex > index;
            const nextVideo = currentVideoIndex < index;
            if (currentVideoIndex === index) {
              progress = videoProgress;
            } else if (previousVideo) {
              progress = 100;
            } else if (nextVideo) {
              progress = 0;
            }

            return (
              <VideoProgress
                key={index}
                videoIndex={index}
                progress={progress}
                description={description}
                heading={heading}
                disabled={previousVideo || nextVideo}
                onChangeVideo={changeVideo}
                onHover={preloadVideo}
              />
            );
          })}
        </div>
        <div className={styles.buttonsContainer}>
          {isVideoMuted ? (
            <MutedIconSvg
              onClick={toggleVideoSound}
              className={styles.volumeBtn}
            />
          ) : (
            <VolumeUpIconSvg
              onClick={toggleVideoSound}
              className={styles.volumeBtn}
            />
          )}
          <FullVideoBtn onClick={showModal} />
        </div>
      </div>
      <YoutubeEmbededModal
        showModal={isModalShown}
        src={selectedVideo.fullVideoSrc}
        onClose={closeModal}
      />
    </div>
  );
});

export { HeroVideo };
