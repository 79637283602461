import React, { useCallback, useEffect, useState } from 'react';
import { useAppearence } from '../../hooks/useAppearence';
import { useMountTransition } from '../../hooks/useMountTransition';
import styles from './blobCursor.module.scss';
import { clsx } from '@/uikit/utils';

const BlobCursor = ({ containerRef }) => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const { isInVisibleArea } = useAppearence(containerRef);
  const hasTransitionedIn = useMountTransition(isInVisibleArea, 400);

  const onMouseMove = useCallback((event) => {
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX, y: clientY });
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove, true);
    return () => document.removeEventListener('mousemove', onMouseMove, true);
  }, []);

  return isInVisibleArea || hasTransitionedIn ? (
    <div
      className={clsx(
        styles.blobContainer,
        hasTransitionedIn ? styles.in : '',
        isInVisibleArea ? styles.visible : '',
      )}
    >
      <div
        className={styles.blob}
        style={{
          transform: `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0) translate3d(-50%, -50%, 0)`,
        }}
      />
      <div
        className={styles.blob}
        style={{
          transform: `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0) translate3d(-50%, -50%, 0)`,
        }}
      />
      <div
        className={styles.blob}
        style={{
          transform: `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0) translate3d(-50%, -50%, 0)`,
        }}
      />
    </div>
  ) : null;
};
export { BlobCursor };
