import React from 'react';

export type SvgInlineProps = {
  src: string;
  className?: string;
  width?: number;
  height?: number;
};

type SVGInlineState = {
  svg: string | null;
  loading: boolean;
};

export class SvgInline extends React.Component<SvgInlineProps, SVGInlineState> {
  override state = {
    svg: null,
    loading: false,
  };

  override componentDidMount() {
    fetch(this.props.src)
      .then((res) => res.text())
      .then((text) => {
        this.setState({ svg: text });
      })
      .catch((err) => console.log('Failed fetching: ', err));
  }

  override render() {
    const { loading, svg } = this.state;
    const { className, width, height } = this.props;

    if (loading) {
      return <div className="spinner" />;
    } else if (!svg) {
      return <div className="error" />;
    }
    return (
      <div
        className={className}
        style={{ width, height }}
        dangerouslySetInnerHTML={{ __html: this.state.svg }}
      />
    );
  }
}
