import React from 'react';

import { Block } from '@/blocks/base';
import { BlockContainer } from '@/blocks/base/block-container';
import { RemoteImage } from '@/uikit/remote-image';
import { Typography } from '@/uikit/Typography';

import {
  rootStyleLeft,
  rootStyleRight,
  contentStyleLeft,
  // contentStyleRight,
  artStyle,
  labelStyleNormal,
  labelStyleHighlighted,
  titleStyle,
  logoSvgStyle,
  blockStyle,
  textStyle,
  actionWrapperStyle,
} from './styles.css';
import { renderActions } from '../../schema-mappers';
import { FlexContainer } from '@/uikit/container';

const block = Block('blockHImage', (props) => {
  const {
    label,
    title,
    text,
    image,
    settings,
    actions,
    imageAlign,
    labelHighlight,
  } = props;

  const renderImage = () =>
    image && (
      <div className={artStyle}>
        <RemoteImage
          className={logoSvgStyle}
          image={image}
          sizes={{
            mobile: '90vw',
            tablet: '40vw',
            desktop: '30vw',
          }}
        />
      </div>
    );

  const [rootClass, contentClass] = {
    left: [rootStyleLeft, contentStyleLeft],
    // NOTE: contentStyleLeft is not a bug - by UI design
    //
    right: [rootStyleRight, contentStyleLeft],
  }[imageAlign || 'left'] || [rootStyleLeft, contentStyleLeft];

  const renderText = () => (
    <div className={contentClass}>
      {label && (
        <Typography.subtitle
          className={labelHighlight ? labelStyleHighlighted : labelStyleNormal}
        >
          {label}
        </Typography.subtitle>
      )}
      {title && <Typography.h4 className={titleStyle}>{title}</Typography.h4>}
      {text && <Typography.p className={textStyle}>{text}</Typography.p>}
      {actions && (
        <div className={actionWrapperStyle}>{renderActions(actions)}</div>
      )}
    </div>
  );

  return (
    <BlockContainer
      className={blockStyle}
      defaultTheme="light"
      settings={settings}
    >
      <FlexContainer
        alignItems="start"
        justifyContent="center"
        className={rootClass}
      >
        {renderImage()}
        {renderText()}
      </FlexContainer>
    </BlockContainer>
  );
});

export default block;
