import React from 'react';

import { RemoteImage } from '@/uikit/remote-image';
import styles from './logo-strip.module.scss';
import { LayoutContainer } from '../../components/layout';
import { Block } from '@/blocks/base';

const LogosStrip = Block('logosStrip', (props) => {
  const { logos } = props;
  return (
    <section id="clients" className={styles.logosContainer}>
      <LayoutContainer>
        <div className={styles.logosContent}>
          {logos?.map(({ logo, alt }, index) => (
            <div key={index} className={styles.clientLogo}>
              <RemoteImage image={logo as any} alt={alt} />
            </div>
          ))}
        </div>
      </LayoutContainer>
    </section>
  );
});

export { LogosStrip };
