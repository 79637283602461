import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './tech-transform-section.module.scss';
import { LayoutContainer } from '../../components/layout';
import { useAppearence } from '../../hooks/useAppearence';
import { Block } from '@/blocks/base';
import { RichText } from '@/uikit/rich-text-old';
import { RemoteImage } from '@/uikit/remote-image';
import { clsx } from '@/uikit/utils';
import { Button } from '@/components/button';
import { ModalContext } from '@/contexts/globalActions';

interface CardProps {
  renderText: () => ReactElement;
  renderIllustration: () => ReactElement;
  renderLink?: () => ReactElement;
  accentBg?: boolean;
  horizontal?: boolean;
  marginLeft?: boolean;
  marginRight?: boolean;
}

const Card = (props: CardProps) => {
  const isAccentClass = props.accentBg ? 'accent' : '';
  const isHorizontalClass = props.horizontal ? 'horizontal' : '';
  const isMarginLeftClass = props.marginLeft ? 'margin-left' : '';
  const isMarginRightClass = props.marginRight ? 'margin-right' : '';

  return (
    <div
      className={clsx(
        styles.card,
        isAccentClass ? styles.accent : '',
        isHorizontalClass ? styles.horizontal : '',
        isMarginLeftClass ? styles.marginLeft : '',
        isMarginRightClass ? styles.marginRight : '',
      )}
    >
      <div className={styles.illustration}>{props.renderIllustration()}</div>
      <div className={styles.cardTextContainer}>
        {props.renderText()}
        {props.renderLink && props.renderLink()}
      </div>
    </div>
  );
};

const backgroundProgressConfig: [number, string][] = [
  [15, '#080D1C'],
  [30, '#ECEFFD'],
];

const TechTransformSections = Block('techTransform', (props) => {
  const { heading, description, cards } = props;
  const context = useContext(ModalContext);

  const containerRef = useRef<HTMLElement>(null);
  const cardsRef = useRef<HTMLDivElement>(null);
  const { visibleClass } = useAppearence(cardsRef);

  const [currentColor, setCurrentColor] = useState('#080D1C');
  const handleScroll = useCallback(() => {
    if (containerRef.current === null) {
      return;
    }

    const top = containerRef.current.getBoundingClientRect().top;
    const height = containerRef.current.offsetHeight;
    const progress = ((height - (height + top)) / height) * 100;
    const colorConfig = backgroundProgressConfig.find(
      (progressConfig) => progress <= progressConfig[0],
    );

    if (colorConfig) {
      setCurrentColor(colorConfig[1] as string);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  });

  return (
    <section
      ref={containerRef}
      className={styles.techTransformContainer}
      style={{ backgroundColor: currentColor }}
    >
      <LayoutContainer maxWidth>
        <div className={styles.textContainer}>
          <h3 className={styles.heading}>{heading}</h3>
          <div className={styles.description}>
            <RichText body={description as any} />
          </div>
        </div>
        <div
          ref={cardsRef}
          className={clsx(
            styles.cardsContainer,
            visibleClass ? styles.visible : '',
          )}
        >
          {cards?.map((card, index) => {
            const { image, direction, content, button, bgColor } = card;
            return (
              <Card
                key={index}
                renderIllustration={() => (
                  <RemoteImage
                    image={image as any}
                    alt="Discovery"
                    className={styles.imageWrapper}
                    style={{ maxHeight: '100%' }}
                  />
                )}
                renderText={() => (
                  <div className={styles.contentWrapper}>
                    <RichText body={content as any} />
                  </div>
                )}
                renderLink={
                  (button
                    ? () => (
                        <Button
                          onClick={
                            button.isLink
                              ? null
                              : (context?.setModalState as any)
                          }
                          data={button}
                        />
                      )
                    : null) as any
                }
                accentBg={bgColor === 'accent'}
                horizontal={direction === 'horizontal'}
              />
            );
          })}
        </div>
      </LayoutContainer>
    </section>
  );
});

export { TechTransformSections };
