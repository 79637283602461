'use client';

import { getResolvedImage } from '@/core/sanityAPI/types';
import type { SanityImageObject } from '@sanity/image-url/lib/types/types';
import Image from 'next/image';
import React from 'react';
import { SvgInline } from '../SVGUrl';

type SvgPropTypes = {
  className?: string;
  src?: string;
  width?: number;
  height?: number;
  preserveAspectRatio?: string;
  alt?: string;

  asset?: SanityImageObject;
};

export const Svg = ({
  className,
  asset,
  alt,
  src,
  height,
  width,
}: SvgPropTypes) => {
  const resolvedAsset = getResolvedImage(asset);

  if (resolvedAsset) {
    src = resolvedAsset.src;
    width = resolvedAsset.width;
    height = resolvedAsset.height;
  }

  if (src && src.includes('https')) {
    return (
      <SvgInline
        src={src}
        className={className}
        width={width}
        height={height}
      />
    );
  } else {
    return (
      <Image
        className={className}
        src={src}
        alt={alt}
        width={width}
        height={height}
      />
    );
  }
};
