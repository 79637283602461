import React from 'react';

import { classRoot, classSelected, classNormal } from './tabBar.css';

interface Props {
  children: JSX.Element[];
  selectedIndex?: number;
  onTabClick?: (index: number) => void;
}

const component = ({ children, selectedIndex, onTabClick }: Props) => {
  const handleTabClick = (evt, index: number) => {
    evt?.preventDefault();
    onTabClick?.(index);
  };

  return (
    <ul className={classRoot}>
      {children.map((node, index) => {
        return (
          <li key={index}>
            <a
              href={'#'}
              onClick={(evt) => handleTabClick(evt, index)}
              className={index === selectedIndex ? classSelected : classNormal}
            >
              {node}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default component;
