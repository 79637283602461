import { Block } from '@/blocks/base';
import { RichText } from '@/uikit/rich-text';
import React from 'react';

import Section from '@/uikit/Section';

import { containerStyle, sectionContentStyle } from './styles.css';
import { widthClass } from '../../base/config-width.css';
import { alignClass } from '../../base/config-align.css';
import { clsx } from '@/uikit/utils';

const block = Block('blockBody', ({ width, align, body, theme }) => {
  const resultClass = clsx(
    widthClass({ width }),
    alignClass({ align }),
    containerStyle,
  );

  return (
    <Section className={resultClass} contentStyle={sectionContentStyle}>
      <RichText body={body} theme={theme} />
    </Section>
  );
});

export default block;
