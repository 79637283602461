import React from 'react';

import { ButtonSchema } from '@/schema';
import styles from './styles.module.scss';
import { clsx } from '@/uikit/utils';
import { Svg } from '@/uikit/Svg';

interface ButtonProps {
  data: ButtonSchema;
  onClick: () => void;
}

export const Button = (props: ButtonProps) => {
  const { variant, text, icon, isLink, link } = props.data;
  let content: JSX.Element | null = null;
  let iconContent: JSX.Element | null = null;

  if (icon !== undefined) {
    iconContent = (
      <>
        <i aria-hidden className={styles.iconWrapper}>
          <Svg asset={icon} width={24} height={24} />
        </i>
      </>
    );
  }

  if (isLink) {
    content = (
      <a href={link.href} className={styles.link}>
        {text}
        {iconContent}
      </a>
    );
  } else {
    content = (
      <button
        className={clsx(styles.button, styles[variant])}
        onClick={props.onClick}
      >
        {text}
        {iconContent}
      </button>
    );
  }

  return <div className={clsx(styles.wrapper, styles[variant])}>{content}</div>;
};
