import React from 'react';

import { BlockMapping } from '@/blocks/base';

import { ArchitecturalReviewSection } from './architectural-review-section/architectural-review-section';
import { HeroVideo } from './hero-video/hero-video';
import { LogosStrip } from './logos-strip';
import { TechTransformSections } from './tech-transform-section';
import { BlogPostPreview } from './blog-post-preview';

import BlockBody from './builder/Body';
import BlockHImage from './builder/HImage';
import BlockCollection from './builder/Collection';
import BlockTabs from './builder/Tabs';
import BlockFaq from './builder/Faq';
import BlockRef from './builder/Ref';

const mapping: BlockMapping = {
  architecturalReview: { component: ArchitecturalReviewSection },
  heroVideo: { component: HeroVideo },
  logosStrip: { component: LogosStrip },
  techTransform: { component: TechTransformSections },
  blogPostPreview: { component: BlogPostPreview },

  blockBody: { component: BlockBody },
  blockHImage: { component: BlockHImage },
  blockCollection: { component: BlockCollection },
  blockTabs: { component: BlockTabs },
  blockFaq: { component: BlockFaq },
  blockRef: { component: BlockRef },
};

export default mapping;

export function renderBlock(block: any, index = 0, mappings = mapping) {
  if (!block) {
    return null;
  }

  const blockConfig = mappings[block._type];

  if (!blockConfig) {
    return null;
  }

  const Component = blockConfig.component as React.FC<any>;

  return <Component key={index} {...block} />;
}
