import { useCallback, useEffect, useState } from 'react';

export const useAppearence = (ref) => {
  const [visibleClass, setVisibleClass] = useState('');
  const [isInVisibleArea, setIsInVisibleArea] = useState(false);

  const handleScroll = useCallback(() => {
    const offset = -50;
    const elementTop = ref.current?.getBoundingClientRect().top;

    setIsInVisibleArea(
      elementTop + offset >= 0 && elementTop - offset <= window.innerHeight,
    );

    if (isInVisibleArea) {
      setVisibleClass('visible');
    }
  }, [isInVisibleArea]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  });

  return {
    isInVisibleArea,
    visibleClass,
  };
};
