import React, { useState } from 'react';

import { Block } from '@/blocks/base';

import { BlockContainer } from '@/blocks/base/block-container';
import { Typography } from '@/uikit/Typography';

// import { root, title, toggleBar, question, answer } from "./styles.css";

import Styles from './styles.css';
import { FlexContainer } from '@/uikit/container';
import IconClose from '@/svg/icons/chevron_up.svg';
import IconExpand from '@/svg/icons/chevron_down.svg';

const block = Block('blockFaq', (props) => {
  const [toggles, setToggles] = useState(new Set());
  const { title, items, settings } = props;

  const handleToggle = (index: number) => {
    const newToggles = new Set(toggles);
    if (newToggles.has(index)) {
      newToggles.delete(index);
    } else {
      newToggles.add(index);
    }
    setToggles(newToggles);
  };

  return (
    <BlockContainer
      className={Styles.root}
      defaultTheme="light"
      settings={settings}
    >
      <Typography.title className={Styles.title}>{title}</Typography.title>
      {items.map((item, index) => (
        <>
          <FlexContainer
            key={index}
            className={Styles.toggleBar}
            onClick={() => handleToggle(index)}
          >
            <Typography.h4 className={Styles.question}>
              {item.question}
            </Typography.h4>
            {toggles.has(index) ? (
              <IconClose className={Styles.chevron} />
            ) : (
              <IconExpand className={Styles.chevron} />
            )}
          </FlexContainer>
          <Typography.p className={Styles.answer}>
            {toggles.has(index) ? item.answer : ''}
          </Typography.p>
        </>
      ))}
    </BlockContainer>
  );
});

export default block;
