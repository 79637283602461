import React from 'react';

import { Block } from '@/blocks/base';
import { renderBlock } from '@/blocks/mapping';
import { BlockContainer } from '@/blocks/base/block-container';
import { Typography } from '@/uikit/Typography';
import { classTitle } from './styles.css';
import TabBar from './TabBar';

const block = Block('blockTabs', (props) => {
  const { title, settings, items: items } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const selectedTab = items[selectedIndex];
  const selectedContent = selectedTab?.items;

  const blocks = selectedContent?.map((block, index) => {
    return renderBlock(block, index);
  });

  return (
    <BlockContainer defaultTheme="light" settings={settings}>
      <Typography.subtitle className={classTitle}>{title}</Typography.subtitle>
      <TabBar selectedIndex={selectedIndex} onTabClick={setSelectedIndex}>
        {items.map((item, index) => {
          return <span key={index}>{item.title}</span>;
        })}
      </TabBar>
      {blocks}
    </BlockContainer>
  );
});

export default block;
