import React from 'react';
import { Block } from '@/blocks/base';
import { BlockContainer } from '@/blocks/base/block-container';
import { Typography } from '@/uikit/Typography';
import { sectionStyle, containerStyle, titleStyle } from './base.css';
import Icon from './icon.css';
import Image from './image.css';
import { Svg } from '@/uikit/Svg';

const proceedLayout = (style, items) => {
  return items.map((item, index) => {
    return (
      <div key={index} className={style.container}>
        <Svg className={style.image} asset={item.icon} />
        <Typography.p className={style.p}>{item.title}</Typography.p>
      </div>
    );
  });
};

const block = Block('blockCollection', ({ title, type, items, settings }) => {
  return (
    <BlockContainer
      className={sectionStyle}
      defaultTheme={'light'}
      settings={settings}
    >
      <Typography.subtitle className={titleStyle}>{title}</Typography.subtitle>
      <div className={containerStyle}>
        {proceedLayout(type === 'icon' ? Icon : Image, items)}
      </div>
    </BlockContainer>
  );
});
export default block;
